import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5c5f04c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fs-6 fw-bold required" }
const _hoisted_2 = { class: "fs-6 fw-bold required" }
const _hoisted_3 = { class: "fs-6 fw-bold required" }
const _hoisted_4 = { class: "fs-6 fw-bold required" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_ModalLayout = _resolveComponent("ModalLayout")!

  return (_openBlock(), _createBlock(_component_ModalLayout, {
    "max-width": "400px",
    title: _ctx.$t('userDetails')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        rules: _ctx.rules,
        ref: "ruleFormRef",
        model: _ctx.UserDetails
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { prop: "firstName" }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.$t("firstName")), 1),
              _createVNode(_component_el_input, {
                modelValue: _ctx.UserDetails.firstName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.UserDetails.firstName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { prop: "lastName" }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("lastName")), 1),
              _createVNode(_component_el_input, {
                modelValue: _ctx.UserDetails.lastName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.UserDetails.lastName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { prop: "email" }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("login.email")), 1),
              _createVNode(_component_el_input, {
                modelValue: _ctx.UserDetails.email,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.UserDetails.email) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { prop: "companyName" }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("companyName")), 1),
              _createVNode(_component_el_input, {
                modelValue: _ctx.UserDetails.companyName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.UserDetails.companyName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { class: "mt-8" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                size: "large",
                type: "primary",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.submitForm(_ctx.ruleFormRef)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("submit")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["rules", "model"])
    ]),
    _: 1
  }, 8, ["title"]))
}