import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    mode: "default"
  }, {
    default: _withCtx(() => [
      (_ctx.modalState.type !== '')
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalState.type), {
            key: 0,
            payload: _ctx.modalState.payload
          }, null, 8, ["payload"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}