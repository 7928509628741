
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: { type: String, required: false },
    src: { type: String, required: false },
  },
  components: {},
  setup() {
    return {};
  },
});
