
import { defineComponent, onMounted, onBeforeUnmount, computed } from "vue";
import { setModal } from "@/core/helpers/config";
import Close from "./parts/Close.vue";
import { ElMessageBox } from "element-plus";
import useTranslate from "@/core/composable/useTranslate";
import { useStore } from "vuex";

export default defineComponent({
  name: "create-platform-modal",
  components: { Close },
  props: {
    title: String,
    maxWidth: { type: String, default: "550px" },
    id: { type: String },
    showHeader: { type: Boolean, default: true },
  },
  setup(props) {
    const store = useStore();
    onMounted(() => {
      const body = document.body;
      body.style.top = `-${window.scrollY}px`;
      body.style.position = "fixed";
      body.style.width = `100%`;
    });
    onBeforeUnmount(() => {
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = "";
      body.style.top = ``;
      body.style.width = ``;
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    });
    const { translate } = useTranslate();

    const handleClose = () => {
      ElMessageBox.confirm(`${translate("messageBox.message")}`, {
        distinguishCancelAndClose: true,
        confirmButtonText: `${translate("messageBox.confirm")}`,
        cancelButtonText: `${translate("messageBox.cancel")}`,
      }).then(() => {
        setModal("");
      });
    };

    const closeable = computed(() => {
      return store.getters.getModalState.options.closeable;
    });
    return {
      setModal,
      handleClose,
      closeable,
    };
  },
});
