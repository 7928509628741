import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-modal'))),
    class: "cursor-pointer"
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_inline_svg, { src: "/svg/icons/close.svg" })
    ])
  ]))
}