import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17576644"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "modal-header"
}
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["modals", [!_ctx.closeable && 'modals-closeable']]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeable && _ctx.handleClose()))
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["mx-auto modal-content", [!_ctx.closeable && 'modals-closeable']]),
      style: _normalizeStyle({ maxWidth: _ctx.maxWidth })
    }, [
      _createElementVNode("div", {
        class: "modal-content mx-auto",
        id: _ctx.id
      }, [
        (_ctx.showHeader)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
              (_ctx.closeable)
                ? (_openBlock(), _createBlock(_component_Close, {
                    key: 0,
                    class: "close-btn",
                    onCloseModal: _cache[1] || (_cache[1] = () => _ctx.closeable && _ctx.setModal(''))
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ], 8, _hoisted_1)
    ], 6)
  ], 64))
}