
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

//Modals
import SimpleTicketModal from "@/components/modals/wizards/SimpleTicketModal.vue";
import UserDetails from "@/components/modals/wizards/UserDetails.vue";
import EstimatedModal from "@/components/modals/wizards/EstimatedModal.vue";
import MindmapVideoModal from "@/components/modals/wizards/MindmapVideoModal.vue";
import InfoSimulator from "@/components/modals/wizards/InfoSimulator.vue";
export default defineComponent({
  components: {
    SimpleTicketModal,
    EstimatedModal,
    UserDetails,
    InfoSimulator,
    MindmapVideoModal,
  },
  setup() {
    const store = useStore();
    const modalState = computed(() => {
      return store.getters.getModalState;
    });
    return { modalState };
  },
});
