
import { defineComponent } from "vue";
import ModalLoader from "./ModalLoader.vue";

export default defineComponent({
  data() {
    return {};
  },
  props: {},
  methods: {},
  components: { ModalLoader },
});
