
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import ModalLayout from "@/components/modals/ModalLayout.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { GetListSelectTicketResponseDto } from "@/service/index";
import { setModal } from "@/core/helpers/config";
import MultiTextInput from "@/components/inputs/MultiTextInput.vue";
import { NodeType } from "@/components/shared/mindmap/mindmapNodes";
export default defineComponent({
  components: { ModalLayout, MultiTextInput },
  props: {
    payload: {
      type: Object,
      required: false,
    },
  },
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const targetData = ref<any>({
      text: "",
      type: 1,
      relatesPage: undefined,
      relatesSection: undefined,
      userJourney: [""],
      acceptanceTest: [""],
    });
    const loading = ref<boolean>(false);
    const store = useStore();

    watch(
      () => props.payload,

      () => {
        if (props.payload?.id) {
          targetData.value = {
            ...props.payload,
            ...store.getters.getPageAndSectionfrom(
              props.payload.type,
              props.payload.parentId
            ),
          };
        } else if (props.payload?.type) {
          targetData.value = {
            ...props.payload,
            ...store.getters.getPageAndSectionfrom(
              props.payload.type,
              props.payload.parentId
            ),
          };
        }
      },
      { immediate: true }
    );
    const complexities = computed(() => store.getters.getComplexities);
    const nodeTypes = computed(() => store.getters.getNodeTypes);
    const pageSectionsNodes = computed(() =>
      store.getters.getSectionNodesByPageId(targetData.value.relatesPage)
    );
    onMounted(() => {
      store.dispatch(Actions.FETCH_COMPLEXITIES);
      store.dispatch(Actions.FETCH_NODE_TYPES);
    });

    const tickets = computed(() => {
      return store.getters.getTickets as GetListSelectTicketResponseDto;
    });
    const pageNodes = computed(() => {
      return store.getters.getPageNodes;
    });

    const closeable = computed(() => {
      return store.getters.getModalState.options.closeable;
    });

    const autoClose = computed(() => {
      return store.getters.getModalState.payload?.autoClose;
    });
    const closeModal = () => {
      setModal("");
    };
    const rules = ref({
      text: [
        {
          required: true,
          trigger: "change",
        },
      ],
      relatesSection: [{ required: true }],
      relatesPage: [{ required: true }],
    });
    const resetRelates = () => {
      targetData.value.relatesPage = undefined;
      targetData.value.relatesSection = undefined;
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          console.log({ formData: formRef.value });

          loading.value = true;
          /** @todo Set DATA */
          store.dispatch(Actions.SUBMIT_MINDMAP_NODE, targetData.value);
          await new Promise((r) => setTimeout(r, 400));
          if (autoClose.value) {
            setModal("");
          } else {
            await setModal("");
            setModal("SimpleTicketModal", { autoClose: false });
          }
        }
      });
    };

    return {
      targetData,
      submit,
      loading,
      formRef,
      rules,
      tickets,
      complexities,
      nodeTypes,
      pageNodes,
      pageSectionsNodes,
      closeable,
      autoClose,
      NodeType,
      resetRelates,
      closeModal,
    };
  },
});
