import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fca25d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "polygon d-flex align-items-center flex-column" }
const _hoisted_2 = { class: "svg-icon svg-icon-2x svg-icon-primary mb-2 mt-8" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("img", {
        alt: "Logo",
        class: "h-30px",
        src: _ctx.src
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1)
  ]))
}