
import { defineComponent } from "vue";
import ModalLayout from "@/components/modals/ModalLayout.vue";
import { setModal } from "@/core/helpers/config";
export default defineComponent({
  components: { ModalLayout },
  setup() {
    const handelClick = () => {
      setModal("", {}, { isClose: true });
    };
    return {
      handelClick,
    };
  },
});
