
import { defineComponent, reactive, ref } from "vue";
import { setModal } from "@/core/helpers/config";
import ModalLayout from "@/components/modals/ModalLayout.vue";
import { setUserDetails } from "@/core/helpers/user";
import type { ElForm } from "element-plus";
import useTranslate from "@/core/composable/useTranslate";

interface IUserDetails {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
}
export default defineComponent({
  components: { ModalLayout },
  name: "create-branche-modal",
  setup() {
    const { translate } = useTranslate();
    const UserDetails = ref<IUserDetails>({
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
    });
    const ruleFormRef = ref<InstanceType<typeof ElForm>>();
    const submitForm = (formEl: InstanceType<typeof ElForm> | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          console.log("submit!");
          setUserDetails(UserDetails);
          setModal("InfoSimulator", {}, { closeable: false });
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };
    const resetForm = (formEl: InstanceType<typeof ElForm> | undefined) => {
      if (!formEl) return;
      formEl.resetFields();
    };
    const rules = reactive({
      firstName: [
        {
          required: true,
          message: ` ${translate(
            "userDetailsValidation.pleaseInputFirstName"
          )}`,
          trigger: "blur",
        },
        {
          min: 3,
          message: ` ${translate("userDetailsValidation.length")}`,
          trigger: "blur",
        },
      ],
      lastName: [
        {
          required: true,
          message: ` ${translate("userDetailsValidation.pleaseInputLastName")}`,
          trigger: "blur",
        },
        {
          min: 3,
          message: ` ${translate("userDetailsValidation.length")}`,
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: ` ${translate(
            "userDetailsValidation.pleaseInputEmailAddress"
          )}`,
          trigger: "blur",
        },
      ],
      companyName: [
        {
          required: true,
          message: ` ${translate(
            "userDetailsValidation.pleaseInputCompanyName"
          )}`,
          trigger: "blur",
        },
      ],
    });

    return {
      setModal,
      UserDetails,
      resetForm,
      ruleFormRef,
      rules,
      submitForm,
    };
  },
});
