
import { defineComponent } from "vue";
import ModalLayout from "@/components/modals/ModalLayout.vue";
export default defineComponent({
  components: { ModalLayout },
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
});
