
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Array,
    },
    label: {
      type: String,
      required: true,
    },
    id: { type: String },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String },
  },
  setup(props, { emit }) {
    const computedValue = computed<Array<string>>({
      get() {
        return props.modelValue as Array<string>;
      },
      set(val) {
        emit("update:modelValue", val);
      },
    });

    const removeItem = (index) => {
      computedValue.value.splice(index, 1);
    };
    const addItem = () => {
      computedValue.value?.push("");
    };
    return { computedValue, removeItem, addItem };
  },
});
